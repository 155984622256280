@import "@skryv/core-ng1/theme/includes/index";


meemoo-dossier-process-info {
  .tile-content-title {
    &.is-followed-by-task {
      margin-bottom: -$base-spacing;
    }
  }

  .meemoo-loading {
    margin-top: $base-spacing;
  }

  skr-dossier-tile-active-task {
    .info-block {
      margin-bottom: 0;

      &.task {
        background-color: mix(white, $toolbar-background-color-light, 40%);
      }
    }

  }

  .meemoo-info-block {
    margin-left: -$base-spacing;
    margin-right: -$base-spacing;
    padding: $base-spacing;
    display: flex;

    dt {
      font-weight: $font-weight-normal;
      margin: 0;
    }

    dd {
      font-weight: $font-weight-light;

      table {
        width: 100%;
        margin-left: calc($base-spacing/2);
        margin-top: 0;
        margin-bottom: 0;

        td {
          border: 0;
          padding: 0;
          padding-right: $base-spacing;
          overflow-x: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    a {
      color: $base-font-color;
      width: 100%;
    }

    &.attachment {
      cursor: pointer;
    }

    &.attachment:hover, &.document:hover {
      background-color: $gray-light;
    }
    &.attachment > .icon {
      align-self: center;
      margin-right: $base-spacing;
    }
  }

  .meemoo-tasks {
    padding-bottom: $base-spacing;

    &.empty {
      font-weight: $font-weight-light;

      a {
        cursor: pointer;
      }
    }

    &.with-attachments {
      border-top: $base-border;
      padding-top: $base-spacing;
      font-size: 0.8 * $base-font-size;
    }
  }

  .meemoo-attachment-status {
    &.success {
      color: $green;
    }

    &.error {
      color: $red;
    }

    &.warning {
      color: $orange;
    }
  }
}