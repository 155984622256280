@import "@skryv/core-ng1/theme/includes/index";
meemoo-login {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;

  .meemoo-login-center {
    position: relative;
    margin: 5% auto 0;
    width: 50%;
    min-width: 500px;
  }
  .meemoo-login-logo {
    position: absolute;
    left: 0;
    top: 20px;
    width: 250px;
  }
  .meemoo-login-form {
    width: 250px;
    margin-left: 50%;
  }
  .meemoo-login-forgotlink {
    color: $gray;
    font-size: 12px;
    text-decoration: underline;
    float: right;
    line-height: 32px;
  }
  .meemoo-login-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 45%;
    width: 100%;
    border-top: 10px solid $action-color;
  }
  .meemoo-login-footer-img {
    height: 100%;
    width: 32%;
    display: inline-block;
    background-size: 100%;
    float: left;
  }
  .meemoo-login-footer-img-1 {
    background-image: url(./p1.jpg);
  }
  .meemoo-login-footer-img-2 {
    width: 36%;
    background-image: url(./p2.jpg);
  }
  .meemoo-login-footer-img-3 {
    background-image: url(./p3.jpg);
  }
  .meemoo-login-footer-credit {
    background-color: rgba(0,0,0,0.7);
    color: $white;
    position: absolute;
    right: 20px;
    bottom: 20px;
    padding: 10px;
  }
}
